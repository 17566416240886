<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br />
    <div class="container fluid">
      <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link
            to="/production_equipement/glue_dispensing/szd1200"
          >
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="http://v4-upload.goalsites.com/141/image_1527560682_PGB1200.jpg?imageView2/1/w/400/h/360/q/100"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  Fully automatic glue dispenser machine SZD-1200
                </h4>
         
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link
            to="/production_equipement/glue_dispensing/szd1500"
          >
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="http://v4-upload.goalsites.com/141/image_1527561671_SZD1500.jpg?imageView2/1/w/400/h/360/q/100"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                One part  glue dispenser SZD1500
                </h4>
              
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link
            to="/production_equipement/glue_dispensing/szd700"
          >
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="http://v4-upload.goalsites.com/141/image_1527561188_SZD700.jpg?imageView2/1/w/400/h/360/q/100"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  Fully automatic glue dispenser machine SZD-700
                </h4>
               
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link
            to="/production_equipement/glue_dispensing/tg40"
          >
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="http://v4-upload.goalsites.com/141/image_1572419535_%E6%9C%AA%E6%A0%87%E9%A2%98-1.jpg?imageView2/1/w/400/h/360/q/100"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                   High precision glue dispensing machine TG40
                </h4>
               
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
      <div class="row" style="margin-top: 30px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link
            to="/production_equipement/glue_dispensing/pgb700"
          >
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="http://v4-upload.goalsites.com/141/image_1594603912_%E6%9C%AA%E6%A0%87%E9%A2%98-1.jpg?imageView2/1/w/400/h/360/q/100"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                 AB part Glue dispenser Machine PGB-700
                </h4>
                
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link
            to="/production_equipement/glue_dispensing/sd200"
          >
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="http://v4-upload.goalsites.com/141/image_1527560931_sd200.jpg?imageView2/1/w/400/h/360/q/100"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  Manual operation glue dispenser SD-200
                </h4>
              
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link
            to="/production_equipement/glue_dispensing/szd300"
          >
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="http://v4-upload.goalsites.com/141/image_1594605286_%E6%9C%AA%E6%A0%87%E9%A2%98-1.jpg?imageView2/1/w/400/h/360/q/100"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  glue dispenser machine SZD-300
                </h4>
                
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link
            to="/production_equipement/glue_dispensing/pgb1500"
          >
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="http://v4-upload.goalsites.com/141/image_1562568503_PGB1500.jpg?imageView2/1/w/400/h/360/q/100"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                 AB two components glue machine PGB-1500 
                </h4>
               
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>

      <div class="row" style="margin-top: 30px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link
            to="/production_equipement/glue_dispensing/gnd700"
          >
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="http://v4-upload.goalsites.com/141/image_1551337481_GND700.jpg?imageView2/1/w/400/h/360/q/100"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  High Viscosity Glue Dispensing Machine GND-700
                </h4>
                
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link
            to="/production_equipement/glue_dispensing/pgb650x"
          >
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="http://v4-upload.goalsites.com/141/image_1551342120_PGB650X.jpg?imageView2/1/w/400/h/360/q/100"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  Double Preparation Glue Filling Machine PGB-650X
                </h4>
               
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/glue_dispensing/pjl1200">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="http://v4-upload.goalsites.com/141/image_1551423024_PJL1200.jpg?imageView2/1/w/400/h/360/q/100"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  Standard Glue Dispensing Machine PJL-1200
                </h4>
               
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link
            to="/production_equipement/glue_dispensing/qpd300"
          >
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="http://v4-upload.goalsites.com/141/image_1551425985_QPD300.jpg?imageView2/1/w/400/h/360/q/100"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                Disc Bulb Glue Dispensing Machine QPD-300</h4>
                
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
      <div class="row" style="margin-top: 30px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/glue_dispensing/desktop">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="http://v4-upload.goalsites.com/141/image_1551428152_SZD300.jpg?imageView2/1/w/400/h/360/q/100"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                Desktop Glue Dispenser
                </h4>
                
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link
            to="/production_equipement/glue_dispensing/pgb700c"
          >
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="http://v4-upload.goalsites.com/141/image_1562134885_PGB-710.jpg?imageView2/1/w/400/h/360/q/100"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  AB components glue dispensing machine PGB-700 
                </h4>
                
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link
            to="/production_equipement/glue_dispensing/pgb1200"
          >
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="http://v4-upload.goalsites.com/141/image_1562567825_1.jpg?imageView2/1/w/400/h/360/q/100"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  AB part Glue dispenser Machine PGB-1200
                </h4>
                
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
       
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
};
</script>